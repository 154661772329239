import { createTheme } from "@material-ui/core/styles";

const themeObj = {
  palette: {
    primary: {
      main: "#E0FC01",
    },
    secondary: {
      main: "#0084b7",
    },
  },
  typography: {
    fontFamily: "Averta Regular",
  },
  overrides: {
    MuiButton: {
      root: {
        fontFamily: "Averta Bold",
      },
      sizeSmall: {
        padding: "7px 10px ",
      },
    },
    MuiMenuItem: {
      root: {
        fontFamily: "Averta Bold",
      },
    },
    MuiSwitch: {
      root: {
        padding: "0",
        borderRadius: "18px",
        width: "53px",
        height: "24px",
      },
      thumb: {
        width: "18px",
        height: "18px",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.15)",
      },
      switchBase: {
        padding: "3px",
        "&:hover": {
          backgroundColor: "transparent",
        },
        "&&&&$checked": {
          transform: "translateX(calc(100% + 5px))",
          color: "white",
        },
        "&&&&$checked + $track": {
          opacity: "1",
          backgroundColor: "black",
        },
        "&&&&$checked + &&&&$disabled + $track": {
          opacity: ".2",
        },
      },
    },
    MuiOutlinedInput: {
      notchedOutline: {
        borderColor: "none",
        background: "white",
      },
    },
    MuiInputBase: {
      input: {
        zIndex: "10",
      },
    },
    MuiList: {
      root: {
        background: "#0077d6",
        color: "#e0fc01",
      },
    },
    MuiFilledInput: {
      root: {
        borderTopLeftRadius: "4px",
        borderTopRightRadius: "4px",
        borderBottomLeftRadius: "4px",
        borderBottomRightRadius: "4px",
        backgroundColor: "white",
      },

      underline: {
        "&:before": {
          display: "none !important",
        },
      },
    },

    MuiFormLabel: {
      root: {
        marginBottom: "15px",
        color: "#000000",
      },
    },
    MuiFormControl: {
      root: {
        marginBottom: ".8rem",
      },
    },
    MuiRadio: {
      root: {
        color: "black",
      },
    },
    PrivateRadioButtonIcon: {
      root: {
        color: "black",
      },
    },
  },
};

const theme = createTheme(themeObj);
export { theme, themeObj };
