import React, { Component } from "react";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";
import { InfoWindow } from "google-maps-react";
import mapStyle from "../constants/mapStyle";
import Geocode from "react-geocode";
import CircularProgress from "@material-ui/core/CircularProgress";
import makeLowercaseKeys from "../helpers/lowercaseKeys";
import Button from "@material-ui/core/Button";
import Link from "./../components/Link";
import { theme } from "./../theme";
import { ThemeProvider } from "@material-ui/core/styles";
import { ReactComponent as WebsiteIcon } from "../images/icons/ico-website.svg";
import { ReactComponent as LocationIcon } from "../images/icons/ico-location.svg";
import mapIcon1 from "../images/icons/map-markers/LightBlue.svg";
import mapIcon2 from "../images/icons/map-markers/Lilac.svg";
import mapIcon3 from "../images/icons/map-markers/Purple.svg";
import mapIcon4 from "../images/icons/map-markers/Red.svg";
import mapIcon5 from "../images/icons/map-markers/Yellow.svg";
import ProviderWithRouter from "../ProviderWithRouter";
import { ilSauceData } from "../staticData/ilSauceData";
import { maSauceData } from "../staticData/maSauceData";
import { get } from "lodash";

const apiKey = "AIzaSyBuLWEJhtMCAW-R9qP1l019HbheUR3DqRw";
const markerRefs = [];

const mapIcons = [mapIcon1, mapIcon2, mapIcon3, mapIcon4, mapIcon5];

class StoreLocatorPageCustomData extends Component {
  state = {
    activeMarker: null,
    activeID: null,
    infoWindowVisible: false,
    initialCenter: {
      lat: 37.774929,
      lng: -122.419416,
    },
    mapRef: null,
    stores: null,
    zip: null,
    enableGeolocation: false,
    infoMessage: false,
    page: 1,
    totalPages: false,
    brand: "Good News",
    searchLocation: "Illinois",
    limit: 20,
    offset: 0,
    loading: true,
    searchLocationCoords: false,
    zoom: 5,
    renderedOnce: false,
  };

  getStores() {
    const showData =
      this.state.searchLocation === "Illinois" ? ilSauceData : maSauceData;
    const stores = makeLowercaseKeys(showData);
    const searchLocationCoords = {
      lat: stores[0].lat,
      lng: stores[0].lng,
    };
    this.setState({
      stores: stores,
      infoMessage: `Showing ${this.state.searchLocation} Stores`,
      totalPages: Math.floor(stores.total_rows / this.state.limit),
      loading: false,
      bounds: this.getMapBounds(stores, searchLocationCoords),
      searchLocationCoords: searchLocationCoords,
    });
  }

  componentDidMount() {
    //set up google
    Geocode.setApiKey(apiKey);
    Geocode.setLanguage("en");
    Geocode.setRegion("es");
    // Geocode.enableDebug();

    //wait for wholesale customer data from api then set state.stores to it
    this.getStores();
  }

  componentDidUpdate(prevProps, prevState) {
    //the pagination is driven by offset, so we check to see if it changed, and re-run our api call

    if (
      this.state.offset !== prevState.offset ||
      this.state.searchLocation !== prevState.searchLocation
    ) {
      this.getStores();
    }
  }

  componentWillUnmount() {
    // fix Warning: Can't perform a React state update on an unmounted component
    this.setState = (state, callback) => {
      return;
    };
  }

  nextPage() {
    if (this.state.page + 1 <= this.state.totalPages) {
      const offset = this.state.page * this.state.limit;
      this.setState({
        infoWindowVisible: false,
        offset: offset,
        page: this.state.page + 1,
        loading: true,
      });
    }
  }

  prevPage() {
    if (this.state.page - 1 !== 0) {
      const offset = (this.state.page - 2) * this.state.limit;
      this.setState({
        infoWindowVisible: false,
        offset: offset,
        page: this.state.page - 1,
        loading: true,
      });
    }
  }

  getCurrentLocation = () => {
    const options = {
      enableHighAccuracy: false,
      timeout: 10 * 1000,
      maximumAge: 5 * 60 * 1000,
    };
    const success = (pos) => {
      const crd = pos.coords;
      let zip = false;
      Geocode.fromLatLng(crd.latitude, crd.longitude).then(
        (response) => {
          for (
            let i = 0;
            i < response.results[0].address_components.length;
            i++
          ) {
            for (
              let j = 0;
              j < response.results[0].address_components[i].types.length;
              j++
            ) {
              switch (response.results[0].address_components[i].types[j]) {
                case "postal_code":
                  zip = response.results[0].address_components[i].long_name;
                  break;
                default:
                  break;
              }
            }
          }
          this.setState({
            searchLocation: zip,
            zip: zip,
            offset: 0,
            page: 1,
            loading: true,
          });
        },
        (error) => {
          this.setState({
            infoMessage:
              "Could not get your location. Please search by Zip Code",
            loading: false,
          });
        }
      );
    };
    const error = (err) => {
      this.setState({
        infoMessage: "Could not get your location. Please search by Zip Code",
        loading: false,
      });
    };
    navigator.geolocation.getCurrentPosition(success, error, options);
  };

  getMapBounds = (stores, locationMarkerCoords) => {
    const { google } = this.props;
    let bounds = new google.maps.LatLngBounds();
    for (let store of stores) {
      if (store.lat && store.lng) {
        bounds.extend({ lat: store.lat, lng: store.lng });
      }
    }
    bounds.extend({
      lat: locationMarkerCoords.lat,
      lng: locationMarkerCoords.lng,
    });
    return bounds;
  };

  handleMarkerClick = (props, marker) => {
    this.setState({
      activeMarker: marker,
      activeID: props.id,
      zoom: 18,
      infoWindowVisible: true,
    });
  };

  handleListItemClick = (id) => {
    const markerToShow = markerRefs.find((el) => el.props.id === id);

    this.setState({
      activeMarker: markerToShow.marker,
      activeID: markerToShow.props.id,
      infoWindowVisible: true,
    });
  };

  setMarkerRef = (ref) => {
    markerRefs.push(ref);
  };

  setMapRef = (ref) => {
    if (!this.state.mapRef) {
      this.setState({ mapRef: ref });
    } else {
      this.state.mapRef.map.fitBounds(this.state.bounds);
      this.state.mapRef.map.panToBounds(this.state.bounds);
    }
  };

  renderCurrentLocationMarker = () => {
    return (
      <Marker
        position={{
          lat: this.state.searchLocationCoords.lat.toString(),
          lng: this.state.searchLocationCoords.lng.toString(),
        }}
        zIndex={1000000000}
      />
    );
  };

  renderLocations = () => {
    return this.state.stores.map((store, index) => (
      <div
        key={index}
        id={store.id}
        onClick={() => this.handleListItemClick(store.id)}
        className={`single-location ${
          this.state.activeID && this.state.activeID === store.id
            ? "active"
            : "not-active"
        }`}
      >
        <div className="store-information">
          <h2 className="store-name">{store.name}</h2>

          <p className="store-address">
            {store.address}, {store.city}, {store.state}
          </p>

          {store.usage_type && store.usage_type === "Rec" && (
            <p className="usage-type">Adult Use</p>
          )}
          {store.usage_type && store.usage_type === "Med" && (
            <p className="usage-type">Medical</p>
          )}
          {store.usage_type && store.usage_type === "Both" && (
            <p className="usage-type">Medical and Adult Use</p>
          )}
        </div>

        <div className="store-actions">
          {store.url && (
            <Link
              className="icon-button"
              url={store.url}
              rel="noopener noreferrer"
              target="_blank"
              title={store.url}
            >
              <WebsiteIcon />
              Website
            </Link>
          )}

          {!store.delivery_only && (
            <Link
              className="icon-button"
              url={`https://www.google.com/maps/dir/?api=1&destination=${store.address},${store.city},${store.state},${store.zip}`}
              rel="noopener noreferrer"
              target="_blank"
              title="Directions"
            >
              <LocationIcon />
              Directions
            </Link>
          )}
        </div>
      </div>
    ));
  };

  renderMarkers = () => {
    let count = 0;
    return this.state.stores.map((store, i) => {
      const marker = (
        <Marker
          key={i}
          id={store.id}
          position={{
            lat: store.lat && store.lat.toString(),
            lng: store.lng && store.lng.toString(),
          }}
          icon={mapIcons[count]}
          onClick={this.handleMarkerClick}
          ref={this.setMarkerRef}
        />
      );
      count++;

      if (count === 5) {
        count = 0;
      }
      return marker;
    });
  };

  renderInfoWindowData = (id) => {
    const store = this.state.stores.find((el) => el.id === id);

    if (!store) {
      return <></>;
    }
    const directionsLink =
      store &&
      !store.delivery_only &&
      `https://www.google.com/maps/dir/Current+Location/${store.lat},${store.lng}`;

    return (
      store && (
        <ProviderWithRouter>
          <ThemeProvider theme={theme}>
            <div className="info-window-content">
              <h2 className="store-name">{store.name}</h2>
              {store.usage_type && store.usage_type === "Rec" && (
                <p className="usage-type">Adult Use</p>
              )}
              {store.usage_type && store.usage_type === "Med" && (
                <p className="usage-type">Medical</p>
              )}
              {store.usage_type && store.usage_type === "Both" && (
                <p className="usage-type">Medical and Adult Use</p>
              )}

              <p className="store-address">
                {store.address}, {store.city}, {store.state}
              </p>

              {directionsLink && (
                <Link
                  url={directionsLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    disableElevation
                    className="mr-5"
                  >
                    Directions
                  </Button>
                </Link>
              )}

              {store.url && (
                <Link url={store.url} target="_blank" rel="noopener noreferrer">
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    disableElevation
                    className="mr-5"
                  >
                    Visit Website
                  </Button>
                </Link>
              )}
            </div>
          </ThemeProvider>
        </ProviderWithRouter>
      )
    );
  };

  handleLocation = () => {
    this.getCurrentLocation();
    this.setState({
      loading: true,
      infoMessage: "Getting your current location...",
    });
  };

  showMAStores = () => {
    this.setState({
      searchLocation: "Massachusetts",
    });
  };

  showILStores = () => {
    this.setState({
      searchLocation: "Illinois",
    });
  };

  render() {
    const { google } = this.props;
    const {
      activeMarker,
      activeID,
      infoWindowVisible,
      stores,
      infoMessage,
      page,
      totalPages,
      loading,
      searchLocationCoords,
    } = this.state;

    if (!stores) {
      return (
        <div className="loading-screen">
          <CircularProgress
            style={{
              position: "absolute",
              color: "gray",
              top: "calc(50% - 25px)",
              left: "calc(50% - 25px)",
              width: "50px",
              height: "50px",
            }}
          />
        </div>
      );
    }

    return (
      stores && (
        <div className="custom-map">
          <div className="map-wrapper">
            <Map
              ref={(ref) => this.setMapRef(ref)}
              google={google}
              position="relative"
              className="interactive-map"
              zoomControl={true}
              mapTypeControl={false}
              scaleControl={true}
              rotateControl={true}
              scrollwheel={false}
              fullscreenControl={false}
              streetViewControl={true}
              gestureHandling="greedy"
              zoom={18}
              styles={mapStyle}
            >
              {this.renderMarkers()}

              {searchLocationCoords && this.renderCurrentLocationMarker()}

              <InfoWindow
                marker={activeMarker}
                visible={infoWindowVisible}
                children={this.renderInfoWindowData(activeID)}
              />
            </Map>

            <div className="locations-list-wrapper custom-map">
              <div className="locations-search-form-wrapper">
                <h3 className="locations-list-title mb-15">
                  Find Your Hot Sauce!
                </h3>

                <div className="mb-20" style={{ display: "flex" }}>
                  <Button
                    disableElevation={true}
                    variant="contained"
                    color="primary"
                    onClick={this.showILStores}
                    size="small"
                    style={{ alignSelf: "center", marginLeft: "20px" }}
                  >
                    Illinois Stores
                  </Button>
                  <Button
                    disableElevation={true}
                    variant="contained"
                    color="primary"
                    onClick={this.showMAStores}
                    size="small"
                    style={{ alignSelf: "center", marginLeft: "10px" }}
                  >
                    Massachusetts Stores
                  </Button>
                </div>
              </div>

              {infoMessage && <div className="info-message">{infoMessage}</div>}

              {page && totalPages > 1 && (
                <div className="pagination-nav">
                  <div className="page-number">
                    Page {page} of {totalPages}
                  </div>
                  <div className="page-controls">
                    <span onClick={() => this.prevPage()}>Previous</span>
                    <span onClick={() => this.nextPage()}>Next</span>
                  </div>
                </div>
              )}

              <div className="locations-list">
                {loading ? (
                  <CircularProgress
                    style={{
                      position: "absolute",
                      color: "gray",
                      top: "calc(50% - 25px)",
                      left: "calc(50% - 25px)",
                      width: "50px",
                      height: "50px",
                    }}
                  />
                ) : (
                  this.renderLocations(stores)
                )}
              </div>
            </div>
          </div>
        </div>
      )
    );
  }
}

export default GoogleApiWrapper({
  apiKey: apiKey,
})(StoreLocatorPageCustomData);
