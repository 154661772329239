import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { get } from "lodash";
import mobileOverlay from "../images/overlays/mobilePens.png";
import desktopOverlay from "../images/overlays/desktopPens.png";

const StlSection = styled.section`
  margin-top: 70px;
  color: white;
  width: 100%;
  height: 100%;

  .new-hero-copy h1 {
    text-align: center;
    line-height: 74px;
    .yellow-color {
      position: relative;
      bottom: 11px;
      display: inline-block;
      transform: rotate(-4deg);
    }

    @media only screen and (min-width: 460px) {
      padding-bottom: 40px;
    }
    @media only screen and (min-width: 550px) {
      padding-bottom: 80px;
      padding-top: 60px;
    }
    @media only screen and (min-width: 810px) {
      padding-bottom: 130px;
    }

    @media only screen and (min-width: 1310px) {
      padding-top: 80px;
      padding-bottom: 170px;
    }
    @media only screen and (min-width: 1386px) {
      padding-bottom: 230px;
      padding-top: 120px;
    }
    @media only screen and (min-width: 1626px) {
      padding-bottom: 300px;
      padding-top: 170px;
    }
    @media only screen and (min-width: 1829px) {
      padding-bottom: 350px;
    }
  }
`;

const StlOverlay = styled.img`
  position: absolute;
  top: 8%;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 2;
  animation: mymove 1500ms;
  opacity: 0;
  animation-delay: 500ms;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;

  @media only screen and (min-width: 460px) {
    top: 0%;
  }

  @media only screen and (min-width: 617px) {
    top: 4%;
  }

  @media only screen and (min-width: 1063px) {
    top: 0%;
  }

  @media only screen and (min-width: 1310px) {
    top: 5%;
  }
`;

export default function NewHero(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));
  const copy = get(slice, "primary.copy");

  return (
    <StlSection className="relative">
      <StlOverlay
        src={isMobile ? mobileOverlay : desktopOverlay}
        alt=""
        role="presentation"
      />
      <div className="wrapper standard-padding-less new-hero-copy">
        <PrismicRichText render={copy} />
      </div>
    </StlSection>
  );
}
