import React, { useEffect } from "react";
import PrismicRichText from "../components/PrismicRichText";
import { get, map } from "lodash";
import { useSelector, useDispatch } from "react-redux";
import Link from "../components/Link";

export default function BigGameLocations(slice) {
  const dispatch = useDispatch();
  const copy = get(slice, "primary.copy");

  useEffect(() => {
    dispatch({
      type: "PRISMIC_LOAD_SINGLE_PAGE",
      key: "big_game_locations",
    });
  }, [dispatch]);

  const page = useSelector((state) =>
    get(state, "prismic.big_game_locations.data", false)
  );

  const ilLocations = get(page, "il_locations");
  const maLocations = get(page, "ma_locations");

  const renderLocations = (locations) => {
    return map(locations, (el, i) => (
      <Link
        key={i}
        className="location"
        target={get(el, "link.target")}
        url={get(el, "link.url")}
      >
        <div className="location-name font-bold">
          <PrismicRichText render={get(el, "location")} />
        </div>
        <div className="location-info">
          <div className="address">
            <PrismicRichText render={get(el, "address")} />
          </div>
          <div className="city">
            <PrismicRichText render={get(el, "city")} />
          </div>
          <div className="state">
            <PrismicRichText render={get(el, "state")} />
          </div>

          <div className="zip">
            <PrismicRichText render={get(el, "zip")} />
          </div>
        </div>
      </Link>
    ));
  };

  return (
    <section className="orange-background big-game-locations">
      <div className="copy font-bold">
        <PrismicRichText render={copy} />
      </div>
      <div className="locations-container">
        <div className="locations">
          <h3>IL LOCATIONS</h3>
          <div>{renderLocations(ilLocations)}</div>
        </div>
        <div className="locations">
          <h3>MA LOCATIONS</h3>
          <div>{renderLocations(maLocations)}</div>
        </div>
      </div>
    </section>
  );
}
