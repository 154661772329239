import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { get, map } from "lodash";
import styled from "styled-components";

const StlImageWrapper = styled.div`
  img {
    object-fit: cover;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
  }
  .copy {
    display: flex;
  }
  .copy p {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 25px;
    transform: translateY(-50%);
    width: 100%;
    text-align: center;
  }
  @media only screen and (min-width: 1400px) {
    .copy p {
      font-size: 20px;
      top: 35px;
    }
  }
  @media only screen and (min-width: 1700px) {
    .copy p {
      font-size: 30px;
    }
  }
`;

const StlGrid = styled.div`
  @media only screen and (min-width: 640px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @media only screen and (min-width: 1000px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

export default function NewProductsSection(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));

  const showImages = (data) => {
    return map(data, (el, i) => (
      <StlImageWrapper key={i} className="relative">
        <div
          className="copy dinamit-font"
          style={{ color: get(el, "heading_color") }}
        >
          <PrismicRichText render={get(el, "copy")} />
        </div>
        <img
          alt={get(el, "image.alt")}
          src={isMobile ? get(el, "mobile_image.url") : get(el, "image.url")}
        />
      </StlImageWrapper>
    ));
  };

  return (
    <section className="">
      <StlGrid>{showImages(get(slice, "items"))}</StlGrid>
    </section>
  );
}
