import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { get, map } from "lodash";
import styled from "styled-components";
import renderButtons from "../helpers/renderButtons";

const StlSection = styled.section`
  height: 610px;
  width: 100%;
  overflow: hidden !important;
  .copy {
    z-index: 4;
    width: 35%;
    top: 0;
    left: 0;
    padding: 20px 5px 20px 25px;
  }

  @media only screen and (min-width: 1000px) {
    padding: 30px 5px 30px 35px;
    height: 800px;
    .copy {
      h2 {
        font-size: 45px;
        width: 80%;
      }
      padding: 50px 5px 50px 50px;
    }
  }

  @media only screen and (min-width: 1300px) {
    padding: 30px 5px 30px 35px;
    height: 1300px;
    .copy {
      top: 20%;
      transform: translateY(-20%);
      h2 {
        font-size: 90px;
        width: 100%;
        text-align: center;
      }
      p {
        font-size: 24px;
        text-align: center;
        line-height: 1.3;
        max-width: 525px;
        margin: auto;
      }
      padding: 50px 5px 50px 50px;
    }
  }
  @media only screen and (min-width: 1500px) {
    .copy {
      left: 50px;
    }
  }
`;

const StlImg = styled.img`
  position: absolute;
  top: 0;
  right: -20px;
  object-fit: cover;
`;

export default function NewHalfCopyImageSection(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("800"));
  const mobileImage = get(slice, "primary.mobile_image.url");
  const desktopImage = get(slice, "primary.desktop_image.url");
  const imageAlt = get(slice, "primary.desktop_image.alt");
  const copy = get(slice, "primary.copy");

  return (
    <StlSection className="relative deep-yellow-background">
      <StlImg src={isMobile ? mobileImage : desktopImage} alt={imageAlt} />
      <div className="absolute copy orange-font">
        <PrismicRichText render={copy} />
        <div className="big-game-btns">
          {renderButtons(get(slice, "items"))}
        </div>
      </div>
    </StlSection>
  );
}
