import React from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";

const StlButton = styled.button`
  color: #e0fc01;
  border: none;
  background: none;
  font: inherit;
  font-size: 1rem;
  margin-bottom: 10px;
  padding: 0;
  cursor: pointer;
`;

const CookieSettingsLink = () => {
  const dispatch = useDispatch();

  const showCookieSettings = (e) => {
    dispatch({ type: "COOKIE_COMPLIANCE/SHOW_DETAIL" });
  };

  return (
    <StlButton
      onClick={showCookieSettings}
      aria-label="Click to Manage Cookie Settings"
    >
      Cookie Settings
    </StlButton>
  );
};

export default CookieSettingsLink;
