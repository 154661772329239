import React from "react";
import { Button } from "@material-ui/core";
import { get, isEmpty } from "lodash";
import Link from "./../components/Link";

export default function renderButtons(buttons) {
  return buttons.map((button, i) => {
    if (button.onclick) {
      return (
        <Link url="#">
          <Button
            variant={button.button_variant}
            color={button.button_color}
            disableElevation
            onClick={button.onclick ? button.onclick : null}
          >
            {button.cta_text[0].text || button.cta_text}
          </Button>
        </Link>
      );
    } else if (
      !isEmpty(button.cta_text) &&
      (get(button, "cta_text[0].text") || button.cta_text) &&
      (button.cta_link.url || button.cta_link.uid)
    ) {
      return (
        <Link
          key={i}
          to={"/" + get(button, "cta_link.uid")}
          url={get(button, "cta_link.url")}
          target={get(button, "cta_link.target")}
        >
          <Button
            variant={button.button_variant}
            color={button.button_color}
            disableElevation
          >
            {button.cta_text[0].text || button.cta_text}
          </Button>
        </Link>
      );
    }
  });
}
