export const maSauceData = [
  {
    name: "Sunnyside Fall River, MA - Medical",
    address: "1 West Street",
    city: "Fall River",
    state: "MA",
    zip: "02720",
    phone: "(774) 226-7749",
    url: "https://www.sunnyside.shop/store/fall-river-ma-medical",
    lat: 41.72764205932617,
    lng: -71.14494323730469,
    id: 1,
  },
  {
    name: "Sunnyside Framingham, MA",
    address: "250 Worcester Road",
    city: "Framingham",
    state: "MA",
    zip: "01702",
    phone: "(508) 834-7030",
    url: "https://www.sunnyside.shop/store/framingham-ma",
    lat: 42.2984504699707,
    lng: -71.40397644042969,
    id: 2,
  },
  {
    name: "Sunnyside Leicester, MA",
    address: "1764 Main Street",
    city: "Leicester",
    state: "MA",
    zip: "01524",
    phone: "(508) 859-0460",
    url: "https://www.sunnyside.shop/store/leicester-ma",
    lat: 42.25246564015883,
    lng: -71.94606739637833,
    id: 3,
  },
  {
    name: "Sunnyside Worcester, MA",
    address: "1023 Southbridge Street",
    city: "Worcester",
    state: "MA",
    zip: "01610",
    phone: "(508) 571-1020",
    url: "https://www.sunnyside.shop/store/worcester-ma",
    lat: 42.23153966726889,
    lng: -71.81922962942686,
    id: 4,
  },
];
