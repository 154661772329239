import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";
import { get } from "lodash";
import fireOverlay from "../images/overlays/fire.png";
import fireOverlay2 from "../images/overlays/fire2.png";

const StlSection = styled.section`
  margin-top: 70px;
  color: white;
  width: 100%;
  height: 100%;
  min-height: 535px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  max-height: 1000px;
  .big-game-hero-copy {
    color: #fe5e00;
  }
  .big-game-hero-copy h1 {
    line-height: 74px;
  }

  .big-game-hero-copy h1 .sauce-pattern {
    background-image: url("${fireOverlay2}");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    position: relative;
    bottom: 6px;
    display: inline-block;
    transform: rotate(-5deg);
    font-size: 95px;
    -webkit-text-fill-color: transparent;
    -webkit-filter: drop-shadow(1px 1px #963e11);
    filter: drop-shadow(1px 1px #963e11);
  }

  @media only screen and (min-width: 768px) {
    .wrapper {
      margin: 0;
    }
    .big-game-hero-copy h1 {
      font-size: 140px;
      display: inline-block;
      padding: 50px 0 0 50px;
      line-height: 110px;
    }
    .big-game-hero-copy h1 .sauce-pattern {
      font-size: 145px;
    }
  }

  @media only screen and (min-width: 1350px) {
    // background-attachment: fixed;
    .big-game-hero-copy h1 {
      font-size: 180px;
      line-height: 150px;
    }
    .big-game-hero-copy h1 .sauce-pattern {
      font-size: 195px;
    }
  }

  @media only screen and (min-width: 1400px) {
    margin-top: 82px;
  }

  @media only screen and (min-width: 1600px) {
    .big-game-hero-copy h1 {
      font-size: 200px;
      line-height: 170px;
    }
    .big-game-hero-copy h1 .sauce-pattern {
      font-size: 215px;
    }
  }
`;

const StlFakeButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 100%;
  p {
    background: #fe5e00;
    font-family: "Dinamit-SemiBold";
    text-transform: uppercase;
    width: 93%;
    text-align: center;
    border-radius: 30px;
    padding: 5px;
    margin-bottom: 30px;
    color: #f9c004;
    max-width: 370px;
    @media only screen and (min-width: 1000px) {
      position: absolute;
      top: 70px;
      right: 70px;
      font-size: 20px;
      white-space: nowrap;
      max-width: 450px;
    }

    @media only screen and (min-width: 1350px) {
      font-size: 26px;
      max-width: 590px;
    }
  }
`;

export default function NewBigGameHero(slice) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("600"));
  const copy = get(slice, "primary.copy");
  const mobileImage = get(slice, "primary.mobile_image.url");
  const desktopImage = get(slice, "primary.desktop_image.url");
  const fauxButton = get(slice, "primary.faux_button_copy");

  const backgroundStyle = {
    backgroundImage: isMobile
      ? "url(" + mobileImage + ")"
      : "url(" + desktopImage + ")",
  };

  return (
    <StlSection className="relative" style={backgroundStyle}>
      <div className="wrapper standard-padding-less big-game-hero-copy relative">
        <PrismicRichText render={copy} />
      </div>
      <StlFakeButton>
        <PrismicRichText render={fauxButton} />
      </StlFakeButton>
    </StlSection>
  );
}
