import React from "react";
import RenderNewsletterSection from "./NewsletterSection";
import RenderInstagramSection from "./InstagramSection";
import RenderFormsSection from "./FormsSection";
import RenderMoodsSection from "./MoodsSection";
import RenderTextSection from "./TextSection";
import RenderTextImageSection from "./TextImageSection";
import RenderProductsSection from "./ProductsSection";
import RenderCardSwipeSection from "./CardSwipeSection";
import RenderFeaturedSection from "./FeaturedSection";
import RenderFAQSection from "./FAQSection";
import PDFSection from "./PDFSection";
import IFrameSlice from "./IFrameSlice";
import StoreLocatorSauceFinder from "./StoreLocatorSauceFinder";
import { Store } from "@material-ui/icons";
import NewHero from "./NewHero";
import NewProductsSection from "./NewProductsSection";
import NewTextSection from "./NewTextSection";
import NewBigGameHero from "./NewBigGameHero";
import NewHalfCopyImageSection from "./NewHalfCopyImageSection";
import BigGameLocations from "./BigGameLocations";

export default function Slices(props) {
  if (!document) {
    return null;
  }
  return props.slices.map((slice, index) => (
    <div key={index}>
      {slice.slice_type === "featured_section" && RenderFeaturedSection(slice)}
      {slice.slice_type === "text_section" && RenderTextSection(slice)}
      {slice.slice_type === "products_section" && RenderProductsSection(slice)}
      {slice.slice_type === "card_swipe_section" &&
        RenderCardSwipeSection(slice)}
      {slice.slice_type === "text_image_section" &&
        RenderTextImageSection(slice)}
      {slice.slice_type === "newsletter_slice" &&
        RenderNewsletterSection(slice)}
      {slice.slice_type === "instagram_section" &&
        RenderInstagramSection(slice)}
      {slice.slice_type === "forms_section" &&
        RenderFormsSection(slice, props.directLink)}
      {slice.slice_type === "moods_section" && RenderMoodsSection(slice)}
      {slice.slice_type === "faq_section" && RenderFAQSection(slice)}
      {slice.slice_type === "pdf_section" && PDFSection(slice)}
      {slice.slice_type === "iframe_slice" && IFrameSlice(slice)}
      {slice.slice_type === "store_locator_sauce_slice" &&
        StoreLocatorSauceFinder(slice)}
      {slice.slice_type === "new_hero" && NewHero(slice)}
      {slice.slice_type === "new_text_section" && NewTextSection(slice)}
      {slice.slice_type === "new_products_section" && NewProductsSection(slice)}
      {slice.slice_type === "big_game_hero" && NewBigGameHero(slice)}
      {slice.slice_type === "half_copy_image_section" &&
        NewHalfCopyImageSection(slice)}
      {slice.slice_type === "big_game_locations_slice" &&
        BigGameLocations(slice)}
    </div>
  ));
}
