export const ilSauceData = [
  {
    name: "Sunnyside Buffalo Grove, IL",
    address: "830 Milwaukee Avenue",
    city: "Buffalo Grove",
    state: "IL",
    zip: "60089",
    phone: "(847) 495-2397",
    url: "https://www.sunnyside.shop/store/buffalo-grove-il",
    lat: 42.16712951660156,
    lng: -87.92169189453125,
    id: 1,
  },

  {
    name: "Sunnyside Champaign, IL",
    address: "1704 S. Neil Street Suite C",
    city: "Champaign",
    state: "IL",
    zip: "61820",
    phone: "(217) 305-4009",
    url: "https://www.sunnyside.shop/store/champaign-il",
    lat: 40.09513854980469,
    lng: -88.24505615234375,
    id: 2,
  },
  {
    name: "Sunnyside Chicago, IL - River North",
    address: "436 N Clark St",
    city: "Chicago",
    state: "IL",
    zip: "60654",
    phone: "(312) 481-7380",
    url: "https://www.sunnyside.shop/store/chicago-river-north-il",
    lat: 41.8922119140625,
    lng: -87.63113403320312,
    id: 3,
  },
  {
    name: "Sunnyside Chicago, IL - Wrigleyville/Lakeview",
    address: "3524 North Clark Street",
    city: "Chicago",
    state: "IL",
    zip: "60657",
    phone: "(773) 820-7280",
    url: "https://www.sunnyside.shop/store/chicago-wrigleyville-il",
    lat: 41.94544219970703,
    lng: -87.65509796142578,
    id: 4,
  },

  {
    name: "Sunnyside Danville, IL",
    address: "369 Lynch Drive",
    city: "Danville",
    state: "IL",
    zip: "61834",
    phone: "(217) 903-3576",
    url: "https://www.sunnyside.shop/store/danville-il",
    lat: 40.11851119995117,
    lng: -87.53730773925781,
    id: 5,
  },
  {
    name: "Sunnyside Elmwood Park, IL",
    address: "7955 W. Grand Ave.",
    city: "Elmwood Park",
    state: "IL",
    zip: "60707",
    phone: "(708) 402-8975",
    url: "https://www.sunnyside.shop/store/elmwood-park-il",
    lat: 41.92560958862305,
    lng: -87.81643676757812,
    id: 6,
  },
  {
    name: "Sunnyside Naperville, IL",
    address: "2740 W 75th St.",
    city: "Naperville",
    state: "IL",
    zip: "60564",
    phone: "(630) 884-8061",
    url: "https://www.sunnyside.shop/store/naperville-il",
    lat: 41.74713897705078,
    lng: -88.20303344726562,
    id: 7,
  },
  {
    name: "Sunnyside Rockford, IL",
    address: "2696 McFarland Road",
    city: "Rockford",
    state: "IL",
    zip: "61107",
    phone: "(815) 315-0090",
    url: "https://www.sunnyside.shop/store/rockford-il",
    lat: 42.29507064819336,
    lng: -88.9778060913086,
    id: 8,
  },
  {
    name: "Sunnyside Schaumburg, IL",
    address: "1739 E Golf Road",
    city: "Schaumburg",
    state: "IL",
    zip: "60173",
    phone: "(847) 893-0460",
    url: "https://www.sunnyside.shop/store/schaumburg-il",
    lat: 42.05019760131836,
    lng: -88.05077362060547,
    id: 9,
  },
  {
    name: "Sunnyside South Beloit, IL",
    address: "7000 First Ranger Drive",
    city: "South Beloit",
    state: "IL",
    zip: "61080",
    phone: "(779) 771-6660",
    url: "https://www.sunnyside.shop/store/south-beloit-il",
    lat: 42.490047454833984,
    lng: -88.99787902832031,
    id: 10,
  },
];
