import React from "react";
import PrismicRichText from "../components/PrismicRichText";
import { get } from "lodash";
import cx from "classnames";
import styled from "styled-components";

const StlWrapper = styled.div`
  color: white;
  padding-bottom: 30px;
  &.top-border {
    padding-top: 38px;
    @media only screen and (min-width: 600px) {
      h2 {
        font-size: 62px;
        line-height: 1;
      }
      p {
        font-size: 18px;
        padding-bottom: 22px;
      }
    }
    @media only screen and (min-width: 1000px) {
      padding-top: 68px;
    }
    @media only screen and (min-width: 1400px) {
      padding-top: 131px;
      h2 {
        font-size: 90px;
      }
      p {
        font-size: 30px;
        padding-bottom: 100px;
      }
    }
    @media only screen and (min-width: 1600px) {
      h2 {
        font-size: 132px;
      }
      p {
        font-size: 48px;
      }
    }
  }
`;

export default function NewTextSection(slice) {
  const copy = get(slice, "primary.copy");
  const border = get(slice, "primary.top_border");

  return (
    <section className="text-section standard-padding-less desktop-padding-70">
      <StlWrapper
        className={cx("relative center-align", border === true && "top-border")}
      >
        <PrismicRichText render={copy} />
      </StlWrapper>
    </section>
  );
}
